import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Grid, useMediaQuery, colors, Button} from '@material-ui/core';
import {Image, LearnMoreLink} from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import scrollTo from 'gatsby-plugin-smoothscroll';
import heroImagePNG from "../../../../images/hands_clear.png";
import beforeFacklPNG from "images/before_fackl.png";
import afterFacklPNG from "images/after_fackl.png";



const useStyles = makeStyles(theme => ({
    gridItemBorder: {
        [theme.breakpoints.up('md')]: {
            borderRight: `1px solid ${colors.grey[200]}`,
        },
    },
}));

const Diagrams = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <div className={className} {...rest}>
            <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={12} sm={6} className={classes.gridItemBorder}>
                    <SectionHeader
                        title="Your calling life before Fackl"
                        titleVariant="h5"
                        subtitle="*using your cell for everything*"
                        subtitleVariant="body1"
                        subtitleColor="textPrimary"
                        // ctaGroup={[<LearnMoreLink title="View our FAQs" onClick={() => scrollTo("#faq" )}  />]}
                        disableGutter

                    />
                    <Image
                        src={beforeFacklPNG}
                        alt="You before Fackl"
                        className={classes.image}
                    />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <SectionHeader
                        title="Your calling life with Fackl :)"
                        titleVariant="h5"
                        subtitle="*different numbers for each category of your life*"
                        subtitleVariant="body1"
                        subtitleColor="textPrimary"
                        // ctaGroup={[<LearnMoreLink title="Email us" href="mailto: support@fackl.com"/>]}
                        disableGutter
                    />
                    <Image
                        src={afterFacklPNG}
                        alt="You with Fackl"
                        className={classes.image}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

Diagrams.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default Diagrams;
