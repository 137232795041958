import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';

import {
  Faq,
  Hero,
  Pricings,
  Services,
    Diagrams,
  Users,
    Support,
} from './components';

import {
  services,
  users,
  pricings,
  faq,
} from 'views/data';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  shape: {
    background: theme.palette.alternate.main,
    borderBottomRightRadius: '100%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
}));

const IndexView = () => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <Hero />
      </Section>
      <Section className={classes.pagePaddingTop}>
        <Services data={services} />
      </Section>

      {/*<Section>*/}
        {/*<Partners data={partners} />*/}
      {/*</Section>*/}

      <Divider />

        <Section>
        <Diagrams />
        </Section>




      <SectionAlternate>
        <Pricings data={pricings} />
        </SectionAlternate>

      <Section>
        <Support />
      </Section>

    <Divider />




      {/*<SectionAlternate>*/}
      {/*  <Contact data={props} />*/}
      {/*</SectionAlternate>*/}

      {/*<div className={classes.shape}>*/}
      {/*  <Section className={classes.sectionNoPaddingTop}>*/}
      {/*    <About data={services} />*/}
      {/*  </Section>*/}
      {/*</div>*/}

        {/*<Section>*/}
        {/*    <Reviews data={reviews} />*/}
        {/*</Section>*/}

      {/*<Divider />*/}

         <Section>
          <Faq data={faq} />
        </Section>

      <SectionAlternate>
        <Users data={users} />
      </SectionAlternate>



      {/*<Section>*/}
      {/*  <Features />*/}
      {/*</Section>*/}

      {/*<div className={classes.shape}>*/}
      {/*  /!*<Section>*!/*/}
      {/*  /!*  <Pricings data={pricings} />*!/*/}
      {/*  /!*</Section>*!/*/}
      {/*  <Section className={classes.sectionNoPaddingTop}>*/}
      {/*    <Faq data={faq} />*/}
      {/*  </Section>*/}
      {/*</div>*/}



    </div>
  );
};

export default IndexView;
