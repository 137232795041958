/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import IndexView from 'views/IndexView';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from 'views/SEO';

const IndexPage = () => {

  const descr = "One phone. Multiple numbers. No apps or downloads.";

  return (
      <div>
        <SEO
            title="The Future of Calling"
            description={descr}
        />
        <WithLayout
          component={IndexView}
          layout={Main}
        />
      </div>
  )
};

export default IndexPage;
