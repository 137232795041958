import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button, Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import scrollTo from 'gatsby-plugin-smoothscroll';
import heroImagePNG from "images/hands_clear.png";
// import heroImagePNG from "images/website-hero-image.png";


const useStyles = makeStyles(theme => ({
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
    borderRadius: "5px",

  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2}  alignItems="center">
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <SectionHeader
            title={
              <span>
                A number for each
                <br />
                <span>of your businesses.</span>
              </span>
            }
            subtitle="Take back control of your phone. Use different numbers for different areas of your life, receive fewer unknown calls, and always know which number people dialed to reach you."
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" href="https://app.fackl.com">
                Try for free
              </Button>,
              <Button variant="outlined" color="primary" size="large" onClick={() => scrollTo("#pricing" )}>
                see plans
              </Button>,
            ]}
            align={isMd ? 'left' : 'center'}
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            // src="https://assets.maccarianagency.com/the-front/illustrations/mind-map.svg"
            // src={heroImage}
            src={heroImagePNG}
            alt="TheFront Company"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
